SNI.Application.addService('scroll-tracker', function(application) {

  /**
   *  __   __              ___  ___
   * |__) |__) | \  /  /\   |  |__
   * |    |  \ |  \/  /--\  |  |___
   *
   */

  const history = application.getGlobal('history'),
        debug = application.getService('logger').create('service.stream-scroll-tracker'),
        manager = application.getService('stream-manager'),
        modUtil = application.getService('utility'),
        debugMode = modUtil.isDebug();

  let namespace,
      interval,
      threshold,
      container,
      lasSelector = '',
      viewport = false,
      iteration = 1,
      totalIteration = 1,
      currentInterval = 0,
      currentDistance,
      totalDistance = 0,
      scrollIntervalEdge = 0;

  function setNamespace(val) {
    namespace = val;
  }

  function getNamespace() {
    if (typeof namespace !== 'undefined') {
      return namespace;
    } else {
      return false;
    }
  }

  function setTotalIteration(val) {
    if (typeof val !== 'undefined') {
      totalIteration = val;
    }
  }

  function getTotalIteration() {
    if (typeof totalIteration !== 'undefined') {
      return totalIteration;
    } else {
      return false;
    }
  }

  function setInterval(val) {
    interval = val;
  }

  function setThreshold(val) {
    threshold = val;
  }

  function getThreshold() {
    if (typeof threshold !== 'undefined') {
      return threshold;
    } else {
      return false;
    }
  }

  function setContainer(val) {
    container = val;
  }

  function getContainer() {
    if (typeof container !== 'undefined') {
      return container;
    } else {
      return false;
    }
  }

  function setIteration(val = iteration) {
    iteration = val;
  }

  function getIteration() {
    if (typeof iteration !== 'undefined') {
      return iteration;
    } else {
      return false;
    }
  }

  function updateScrollIntervalEdge() {
    scrollIntervalEdge = $(document).scrollTop();
  }

  function setScrollHandler(val = trackScrollDistance) {
    let name = getNamespace() ? getNamespace() : 'scroll';
    debug.log('Adding ', name);
    let handler = modUtil.throttle(val, 100);
    if (typeof val !== 'undefined') {
      document.addEventListener('scroll', handler);
    }
  }

  function trackScrollDistance() {
    currentDistance = document.documentElement.scrollTop;
    trackViewport();
    if (currentDistance > totalDistance) {
      totalDistance = currentDistance;
      trackScrollInterval();
    }
  }

  function trackScrollInterval(val = interval) {
    let intervalObject = {};
    currentInterval = $(document).scrollTop() - scrollIntervalEdge;
    if (typeof val !== 'undefined') {
      if (currentInterval >= val) {
        updateScrollIntervalEdge();
        updateIteration();
        manager.views.incrementPage();
        intervalObject = {currentInterval};
        application.broadcast('scroll-tracker.interval', intervalObject);
      }
    }
  }

  /*
    CFF-262:  this is no longer called by the scroll event handler.  It is called by trackViewport when needed
  */
  function trackScrollThreshold(index) {
    let maxIndex = manager.getMaxIndex();
    if (index <= maxIndex) {
      debug.log('Request next page @ threshold', index);
      application.broadcast('scroll-tracker.threshold', {
        index
      });
    } else {
      application.broadcast('article-stream.end');
    }
  }

  function pushState(url) {
    if (!debugMode) {
      history.pushState({},'', url);
    } else {
      debug.warn('skipping history.pushState as debug mode is active. url passed:', url);
    }
  }

  function triggerNextPage(trigger, currentEntry) {
    /*
      trigger a new page fetch if available
    */
    if (trigger && !currentEntry.tracked) {
      currentEntry.tracked = true;  // current entry will only trigger threshold once
      trackScrollThreshold(currentEntry.order);
    } else if (trigger && currentEntry.tracked) {
      debug.log('Current entry:', currentEntry.order, ' does not request next page.');
    }
  }

  function getTrackingId(classList) {
    // Take an elements classList and filter to get only the class name that starts with 'cl-'
    let Id = '';
    if (classList && classList.length) {
      let cl = Array.from(classList).filter(el => /^cl-/.test(el));
      if (cl && cl.length) {
        Id = cl[0];
      }
    }
    return Id;
  }

  function trackViewport() {

    if (getViewport()) {
      let allElements = manager.getEntryElements();
      if (allElements) {
        //  Get the most visible item
        let currentItem = getItemsInVP(allElements),
            currentEl = currentItem['el'] && currentItem['el'][0],
            currentSelector = currentEl && getTrackingId(currentEl.classList),
            currentEntry = false,
            visible = currentItem['visible'],
            threshold = getThreshold(),
            trigger = triggerThreshold(visible, threshold); // returns true when threshold is met
        if (!currentSelector) return;
        currentEntry = manager.getEntryBySelector(currentSelector);

        // If scroll tracker can trigger then do so
        if (trigger) {
          triggerNextPage(trigger, currentEntry);
        }

        if (currentSelector && lasSelector !== currentSelector) {
          manager.setCurrentEntry(currentSelector);
          lasSelector = currentSelector;

          if (currentEntry && currentEntry.url) {
            pushState(currentEntry.url);
            let index = currentEntry.order +1;
            let pg = currentEntry.viewed? manager.views.getLastSeen(index): 1;
            /*
                If an new article is in view, increment the article count and set page to 1
                Also increment the total articles viewed
                Otherwise set the page to the last seen value
            */
            if (currentEntry.viewed) {
              manager.views.isNewEntry = false;
              manager.views.setIndex(index, pg);  // changed article
            } else {
              if (index>1) {
                manager.views.isNewEntry = true;
                manager.views.incrementArticle(1);
                manager.views.incrementTotal();
              }

            }
            application.broadcast('scroll-tracker.viewed', {
              item: currentEntry,
              stats: manager.views.behavioralInteraction(),
              current: currentEntry
            });
          }
        }
      }
    }
  }

  function updateIteration() {
    if (getIteration()) {
      let currentIteration = getIteration();
      let updater = getTotalIteration();
      currentIteration++;
      updater++;
      setIteration(currentIteration);
      setTotalIteration(updater);
    }
  }

  function setViewport(val = viewport) {
    viewport = val;
  }

  function getViewport() {
    if (typeof viewport !== 'undefined') {
      return viewport;
    } else {
      return false;
    }
  }

  /*
    CFF-262:
    Take a set of $elements, find which ones are visible and return the most visible one
    The elements are provided by trackViewPort
    This function replaces getClosest for desktop
  */
  function getItemsInVP($set) {
    let arr = [],
        visibleItem ={el:null, visible:0},
        height = window.innerHeight;
    $set.each(function(i, val){
      let $ar = $(val),  // article $
          ar;

      /**
       * Check the entire container for the first article.  Check the article content container for all others (more accurate)
       * The first article can have large right rails and that's why the exception is needed
      */
      if (!$ar.hasClass('container-site')) {
        $ar = $(val).find('.article-content');
      }
      ar = $ar[0];
      if (!(ar && ar['getBoundingClientRect'])) { // exit if this is not a node
        return;
      }
      let pos = ar.getBoundingClientRect(),
          top = pos.top,
          bottom = pos.bottom,
          visible = 0;
      if (top > 0 || bottom >0) { // Is not past view port
        if (top > 0 && top < height) {  // Is fully or partially in viewport
          visible = height - top;
        }
        if (top <0 && bottom >0) {  // Is scrolled but still in viewport
          visible = bottom > height? bottom : bottom;
        }
        arr.push({el: val, visible});
      }
    });
    if (arr.length>0) {
      arr.sort(function(a,b){ // Most visible will be at the end
        if (a && b) {
          return a.visible - b.visible;
        }
      });
      visibleItem = arr.pop();
    }
    visibleItem.visibleAmt = visibleItem.visible / height;
    return visibleItem;
  }

  //  Page is almost scrolled
  function triggerThreshold(visible, threshold) {
    if (!(visible && threshold)) {
      return false;
    }
    let height = window.innerHeight;
    let isInTH = (visible-visible*threshold)< height;
    return isInTH;
  }

  function getClosest($set, offset) {
    let el = null, elOffset, x = offset.left, y = offset.top, distance, dx, dy, minDistance;
    $set.each(function() {
      elOffset = $(this).offset();

      if ((x >= elOffset.left) && (x <= elOffset.right) && (y >= elOffset.top) && (y <= elOffset.bottom)) {
        el = $(this);
        return false;
      }

      let offsets = [[elOffset.left, elOffset.top], [elOffset.right, elOffset.top], [elOffset.left, elOffset.bottom], [elOffset.right, elOffset.bottom]];
      for (let off in offsets) {
        dx = offsets[off][0] - x;
        dy = offsets[off][1] - y;
        distance = Math.sqrt((dx*dx) + (dy*dy));
        if ((minDistance === undefined || distance < minDistance) && (elOffset.top < ($(window).scrollTop() + $(window).height()))) {
          minDistance = distance;
          el = $(this);
        }
      }
    });
    return el;
  }

  /**
   *  __        __          __
   * |__) |  | |__) |    | /  `
   * |    \__/ |__) |___ | \__,
   *
   */

  return {

    setNamespace,
    setInterval,
    setThreshold,
    setContainer,
    getContainer,
    getIteration,
    setScrollHandler,
    setViewport,
    trackViewport,
    getClosest,
    updateScrollIntervalEdge

  };

});

SNI.Application.addModule('profile-promo', function(context) {

  return {
    behaviors: ['lazy-load'],

    init: function() {
      let deviceType = context.getService('device-type');
      let isMobile = deviceType.isMobile;
      let brand = SNI.Config.brand;

      if (isMobile && brand === 'hgtv') {
        let $description = $('[id^=mod-profile-promo] .m-MediaBlock__a-Description');
        $description.insertAfter($description.parent().parent());
      }
    }
  };

});

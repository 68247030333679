SNI.Application.addModule('kd-player', (context) => {

  let debug = context.getService('logger').create('module.video');
  const kdPlayer = context.getService('kd-player');
  const config = context.getConfig();
  const { webPlayer } = config;
  const webPlayerId = webPlayer.playerId;
  const el = context.getElement();
  const getContainer = () => el.querySelector('[data-player-id]');


  const messageHandlers = {
    'kdp-library-loaded': ({ playerId, videoIndex = 0 }) => {
      if (webPlayer && (playerId === webPlayerId)) {
        const playType = 'manual';
        context.broadcast('kdp-library-play-video', { playerId, videoIndex, playType });
      }
    },
    'kdp-library-play-video': ({ playerId, videoIndex = 0, playType = 'manual' }) => {
      if (webPlayer && (playerId === webPlayerId)) {
        kdPlayer.loadNewKDPVideo({playerId, config, videoIndex, playType});
      }
    },
    'kdp-library-end-card-click': ({ playerId, currentIndex, playType = 'manual' }) => {
      const { channels } = webPlayer;
      if (webPlayer && (playerId === webPlayerId)) {
        const onlyOneVideo = channels[0] && channels[0].total && channels[0].total === 1;
        if (onlyOneVideo) {
          // If there's only a single video, when it ends we just need to show the replay button
          kdPlayer.toggleReplayVideoScreen({playerId, showReplayVideoScreen: true});
          return;
        } else {
          // If there's an endcard with a new video or a playlist, we need to destroy this video
          // to be ready for the next one
          const currentIndexNum = parseInt(currentIndex);
          const endIndex = channels[0] && channels[0].end;
          let newIndex = currentIndexNum + 1;
          if (newIndex > endIndex) {
            newIndex = 0;
          }
          kdPlayer.prepareForNewVideo({ playerId, videoIndex: newIndex, playType });
        }
      }
    },
    'ad-break-completed': () => {},
    'invoke-inline-video.kdp': ({ playerId, $videoButtonPoster } ) => {
      kdPlayer.posterClick({playerId, $videoButtonPoster });
    },
    'kdp-remove-module-player': ({ playerId }) => {
      if (playerId === webPlayerId) removePlayer();
    },
    'display-ad-msg': displayAdsText
  }; //end messageHandlers

  // Advertisement copy
  function displayAdsText(state) {}

  function removeAllChildNodes(parent) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  function removePlayer() {
    let video = getContainer();
    removeAllChildNodes(video);
  }

  return {
    messages: Object.keys(messageHandlers),
    onmessage(msg, data) {
      messageHandlers[msg](data);
    },

    init() {
      let playerId;
      if (webPlayer) {
        playerId = webPlayerId;
        debug.log('Ads Config in use');
        kdPlayer.bindVideoElements({ playerId });
      }
    }
  };

});

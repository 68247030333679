// iFrame Component for Engage Sciences Sweepstakes and Voting
// Update: Engage Sciences is now Wayin
import '../services/dependency-loader';

SNI.Application.addModule('engage-sciences', context => {

  let $element = $(context.getElement()),
      debug    = context.getService('logger').create('engage-sciences'),
      NGX      = context.getGlobal('NGX'),
      utility  = context.getService('utility');

  let defaults = {
    ngxIframeSelector: '',
    preEntrySel: '#pre-entry-content'
  };

  let config = Object.assign({}, defaults, context.getConfig());

  // Set up global objects that will be updated by the Analytics library
  function setUpEngageSweeps() {
    window.SNI = window.SNI || {};
    SNI.EngageSweepsManager = SNI.EngageSweepsManager || {};
    return SNI.EngageSweepsManager;
  }

  // Wait for the activate function to be available, allows for testing
  function waitForEngageSweeps(methodName, callback){
    utility.checkDefined(SNI.EngageSweepsManager, methodName, callback);
  }

  return {
    init() {
      const $iframe = config.ngxIframeSelector.length ? $element.find('#' + config.ngxIframeSelector) : [];
      

      if ($iframe.length === 0) {
        debug.error('An iframe could not be found.  Maybe an ngxId field in CQ was left blank?');
        return;
      }

      const iframeUrl = $iframe.attr('data-src');

      setUpEngageSweeps();

      // Check for the activate function.  That will run the Analytics event handlers on the page
      if (NGX && NGX.Embed) {
        let show_warning;
        setTimeout(() => { 
          show_warning = true;
        }, 5000);
        waitForEngageSweeps('activate', function(){
          SNI.EngageSweepsManager.activate(config.sweepsConfig);

          /* Add Custom State Listeners
          For executing code that is only supposed to run when the iframe returns a certain state.
          Use the SNI.EngageSweepsManager.statusReport(); in the console to find available states of iframe.

          #Pre-Entry-Content Function for Smart Home 2015 Photo of the Day
          Current Implemetation of this requires this code to be added in a script tag on the page, in the CMS:
          $(".pullQuote").attr("id","pre-entry-content");   .pullQuote could be any component on the page */

          $iframe.attr('src', iframeUrl).load(() => {
            SNI.EngageSweepsManager.stateListener && SNI.EngageSweepsManager.stateListener('show', () => {
              $(config.preEntrySel).hide();
            });
          });

          if (show_warning) {
            debug.warn('Dependencies loaded slowly but completed.');
          }
        });
      } else {
        debug.error('NGX did not load');
      }
    },

    setUpEngageSweeps,

    waitForEngageSweeps
  };
});

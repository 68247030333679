import '../services/device-type';
import '../services/lazy-load';

SNI.Application.addModule('shopping-embed', (context) => {

  const lazy = context.getService('lazy-load');
  const shopping = context.getService('track-shopping');
  const debug = context.getService('logger').create('module.shopping-embed');
  const deviceType = context.getService('device-type');
  let $element = $(context.getElement());
  let componentMetadata;
  let parentMetadata;
  let clonesLazyLoaded = false;
  let config;
  let $carousel;
  let behaviors =   ['lazy-load', 'load-more'];
  const lazySettings = {
    placeholder: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    trigger: 'inview',
    usePlaceholder: true
  };
  const defaults = {
    carouselSelector: '.m-Carousel',
    slideSelector: '.m-ProductsList__m-Product',
    activeClass: '.active'
  };

  function updateObject(existing, updated) {
    return Object.assign({}, existing, updated);
  }

  function setConfig(oldSettings, newSettings) {
    config = updateObject(oldSettings, newSettings);
  }

  function getMetaData() {
    let metadata1 = $element.data('mdm') || {},
        metadata2 = {};

    if (!$carousel.length) {
      metadata2 = $(config.carouselSelector, context.getElement())
        .find('[data-mdm]')
        .data('mdm');
    } else {
      metadata2 = $carousel
        .find(config.activeClass)
        .find('[data-mdm]')
        .data('mdm');
    }

    return updateObject(metadata1, metadata2);
  }

  function getParentMetaData() {
    const slideMetadata = $carousel
      .find(config.activeClass)
      .find('[data-mdm]')
      .data('mdm');

    return updateObject(parentMetadata, slideMetadata);
  }

  setConfig(defaults, context.getConfig());

  if (config.addTruncation) {
    behaviors.push('truncate');
  }

  return {
    behaviors,

    init() {
      debug.log('init');
      $carousel = $element.find('[data-module="carousel"]');
      componentMetadata = $element.data('mdm');
      parentMetadata = updateObject($element.data('product-count'), {
        componentname: config.parentComponent
      });
    },

    onmessage: {
      'carousel.loaded': data => {
        $element.addClass('is-Loaded');
        if (!deviceType.isMobile && data.carouselId && data.carouselId === $element.find('[data-module="carousel"]').attr('id')) {
          shopping.trackProductImpression(getParentMetaData());
        }
      },
      'carousel.changing': data => {
        if (data.carouselId && data.carouselId === $element.find('[data-module="carousel"]').attr('id') && !clonesLazyLoaded) {
          lazy.addImages({
            container: $element,
            settings: lazySettings
          });
          lazy.forceScroll();
          clonesLazyLoaded = true;
        }
      },
      'carousel.slideUpdated': data => {
        let metadata;

        if (config.parentComponent) {
          metadata = getParentMetaData();
        } else {
          metadata = getMetaData();
        }

        if (data.carouselId && data.carouselId === $element.find('[data-module="carousel"]').attr('id')) {
          shopping.trackProductImpression(metadata);
        }
      }
    },

    onclick(event, element, elementType) {
      switch (elementType) {
        case 'product-link':
          const slideMetadata = $(element).closest(config.slideSelector).data('mdm'),
                _componentMetadata = config.parentComponent ? parentMetadata : componentMetadata,
                metadata = updateObject(_componentMetadata, slideMetadata);

          shopping.trackProductClick(metadata);
          break;
        default:
          break;
      }
    }
  };

});
